import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/chi-siamo',
    name: 'Chi siamo',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChiSiamo.vue')
  },
  {
    path: '/servizi',
    name: 'Servizi',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiziPage.vue')
  },
  {
    path: '/contatti',
    name: 'Contatti',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContattiPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
