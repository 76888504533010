<template>
  <NavBar></NavBar>
  <router-view/>
  <FooterDesk></FooterDesk>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<script>
import NavBar from "@/components/NavBar";
import FooterDesk from "@/components/FooterDesk";

export default {
  components: {
    NavBar,
    FooterDesk,
  }
}
</script>