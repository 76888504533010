<template>
  <div class="head">
    <nav class="navbar navbar-expand-lg">
      <a href="https://multiprado.it" class="navbar-brand">
        <img :src="images[0]" alt="Multiprado pratiche auto Logo">
      </a>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-link">
            <router-link to="/" >Home</router-link>
          </li>
          <li class="nav-link">
            <router-link to="/chi-siamo">Chi siamo</router-link>
          </li>
          <li class="nav-link">
            <router-link to="/servizi">Servizi</router-link>
          </li>
          <li class="nav-link">
            <router-link to="/contatti">Contatti</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      images : [
        require('../assets/multiprado_logo.jpg'),
      ],
    }
  },
}
</script>
