<template>
  <footer>
    <div class="footer row">
      <div class="col-lg-4 col-sm-12">
        <h3>MENU</h3>
        <p><router-link to="/">Home</router-link></p>
        <p><router-link to="/chi-siamo">Chi siamo</router-link></p>
        <p><router-link to="/servizi">Servizi</router-link></p>
        <p><router-link to="/contatti">Contatti</router-link></p>
      </div>
      <div class="col-lg-4 col-sm-12">
        <h3>ORARI</h3>
        <p>
          LUN: <span>09:00 - 12:30 / 15:00 - 18:30</span> <br>
          MAR: <span>09:00 - 12:30 / 15:00 - 18:30</span> <br>
          MER: <span>09:00 - 12:30 / 15:00 - 18:30</span> <br>
          GIO: <span>09:00 - 12:30 / 15:00 - 18:30</span> <br>
          VEN: <span>09:00 - 12:30 / 15:00 - 18:00</span> <br>
          SAB: <span>solo su appuntamento</span> <br>
          DOM: CHIUSI <br>
        </p>
      </div>
      <div class="col-lg-4 col-sm-12">
        <h3>CONTATTI</h3>
        <p>Via Cuneo 11/d, <br> 10042 Nichelino (TO)</p>
        <p><a href="mailto:taddeo@multiprado.info">Mail: <span>taddeo@multiprado.info</span></a></p>
        <p><a href="tel:+390116061235">Tel:<span> 011 606 1235</span></a></p>
        <p><a href="https://api.whatsapp.com/send?phone=3772540816">WhatsaApp:<span> 377 25 40 816</span></a></p>
       </div>
    </div>

    <div class="copyright">
      <p>© {{currentYear}} - Multiprado.it - Tutti i diritti riservati - Developed by <a href="https://violagabriele.com" alt="Viola Gabriele - Web Developer" target="_blank">Viola Gabriele</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterDesk",data () {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>

<style scoped>

</style>